/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const MODAL_CLOSE = 'MODAL_CLOSE';
export const MODAL_OPEN = 'MODAL_OPEN';
export const LOCK_BODY_SCROLL = 'LOCK_BODY_SCROLL';
export const UNLOCK_BODY_SCROLL = 'UNLOCK_BODY_SCROLL';


