import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    const body = $('body');
    const video = $el.find('video');
    let currentBreakoint = Viewport.breakpoint.size;
    let aspect = 0;
    let videoOverlay = $el.find('[data-overlay]');
    let videoInited = false;
    let isPlaying = false;

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);

        if (currentBreakoint >= 600) {
            initVideo();
        }
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('resize', onResize);
        video.off('loadeddata', onPlay);
    };


    function initVideo() {
        videoInited = true;
        Viewport.on('resize', onResize);
        video.on('loadeddata', onPlay);

        if (video.get(0).canPlayType && video.get(0).canPlayType('video/mp4')) {
            video.attr('src', video.data('src'));
            playVideo();
            aspect = video.get(0).width / video.get(0).height;
        } else {
            gsap.to(videoOverlay.get(0), { duration: 0.5, opacity: 0 });
        }
    }

    function onPlay() {
        gsap.to(videoOverlay.get(0),  { duration: 0.5, opacity: 0 });
        video.off('play', onPlay);
        video.css({
            "height": "auto",
            "width": "100%"
        });
        
        aspect = video.width() / video.height();
        video.attr('style', false);
        setSize();
    }

    function setSize() {
        const vidWidth = $el.height() * aspect;
        video.css({
            "width": vidWidth
        });
    }

    function playVideo() {
        isPlaying = true;
        video.get(0).play();
    }

    function pauseVideo() {
        isPlaying = false;
        video.get(0).pause();
    }

    function onBreakpoint(key, data) {
        if (currentBreakoint < 600 && Viewport.breakpoint.size >= 600) {
            if (videoInited) {
                playVideo();
            } else {
                initVideo();
            }
        } else if (currentBreakoint >= 600 && Viewport.breakpoint.size < 600) {
            if (videoInited) {
                pauseVideo();
            }
        }

        currentBreakoint = Viewport.breakpoint.size;
    }

    function onResize(key, data) {
        if (currentBreakoint >= 600) {
            setSize();
        }
    }

    return {
        init,
        destroy
    };

};
