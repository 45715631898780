import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const body = $('body');
    const logo = $el.find('[data-header-logo]');
    const menuOpen = $el.find('[data-menu-open]');
    const menuClose = $el.find('[data-menu-close]');
    const menu = $el.find('[data-menu]');

    let currentBreakpoint = Viewport.breakpoint.size;
    let isShowing = false;

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);

        if (currentBreakpoint < 980) {
            setup();
        }

        $(window).on('keyup', onKeyUp);
    };

    const destroy = () => {
        teardown();
        Viewport.off('breakpoint', onBreakpoint);
        $(window).off('keyup', onKeyUp);
    };

    function onKeyUp(e) {
        if (e.keyCode === 27 && isShowing) {
            hideMenu();
        }
    }

    function setup() {
        gsap.set(menu.get(0), { xPercent: 100 });
        
        menuOpen.on('click', function(e) {
            e.preventDefault();
            showMenu();
        });

        menuClose.on('click', function(e) {
            e.preventDefault();
            hideMenu();
        });
    }

    function teardown() {
        gsap.killTweensOf(menu.get(0));
        gsap.killTweensOf(logo.get(0));
        logo.removeClass('-menu-open');
        menu.attr('style', null);
        logo.attr('style', null);
        menuOpen.off('click');
        menuClose.off('click');
        isShowing = false;
    }

    function getFullWidth() {
        let withoutScrollbar = 0;
        body.css({ overflow: 'hidden' });
        withoutScrollbar = menu.width();
        body.css({ overflow: 'visible' });
        return withoutScrollbar;
    }

    function showMenu() {
        if (!isShowing) {
            gsap.set(menu.get(0), { visibility: 'visible', width: getFullWidth() + 'px' });
            gsap.to(menu.get(0), {
                duration: 0.4, xPercent: 0, ease: 'quint.inOut', onComplete: function() {
                    body.css({ overflow: 'hidden' });
                    menu.css({ width: '100%' });
                    gsap.set(logo.get(0), { opacity: 0 });
                    logo.addClass('-menu-open');
                    gsap.to(logo.get(0), { duration: 0.2, opacity: 0.5 });
                }
            });

            isShowing = !isShowing;
        }
    }

    function hideMenu() {
        if (isShowing) {

            body.css({ overflow: 'visible' });
            gsap.set(menu.get(0), { width: getFullWidth() + 'px', overflow: 'hidden' });

            gsap.to(logo.get(0), {
                duration: 0.1, opacity: 0, onComplete: function() {
                    logo.removeClass('-menu-open');

                }
            });
            gsap.to(menu.get(0), {
                duration: 0.4, xPercent: 100, ease: 'quint.inOut', onComplete: function() {
                    menu.attr('style', null);
                }
            });

            isShowing = !isShowing;
        }
    }


    function onBreakpoint(key, data) {
        if (currentBreakpoint < 980 && Viewport.breakpoint.size >= 980) {
            teardown();
        } else if (currentBreakpoint >= 980 && Viewport.breakpoint.size < 980) {
            setup();
        }
        
        currentBreakpoint = Viewport.breakpoint.size;
    }

    return {
        init,
        destroy
    };

};
