import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import BabusjkaHelper from './lib/BabusjkaHelper';

import { LOCK_BODY_SCROLL, UNLOCK_BODY_SCROLL } from './lib/events';

import gsap from 'gsap';

gsap.defaults({ overwrite: 'auto' });

const $body = $('body');
let lastScrollPosition = 0;

const init = () => {
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();

    BabusjkaHelper.setScrollbarWidth(getScrollbarWidth());

    Dispatch.on(LOCK_BODY_SCROLL, onLockBodyScroll);
    Dispatch.on(UNLOCK_BODY_SCROLL, onUnlockBodyScroll);
};

function onLockBodyScroll() {
    lastScrollPosition = Viewport.scrollTop;
    $body.css('overflow', 'hidden');
    $body.css('width', 'calc(100% - ' + BabusjkaHelper.getScrollbarWidth() + 'px)');
}

function onUnlockBodyScroll() {
    $body.css('overflow', 'auto');
    $body.css('width', '');
}
    
function getScrollbarWidth() {
    const startBodyWidth = $body.width();
    $body.css('overflow', 'hidden');
    const fullBodyWidth = $body.width();
    $body.css('overflow', '');

    return fullBodyWidth - startBodyWidth;
}

require('doc-ready')(init);
