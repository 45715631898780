import $ from '../core/Dom';
import Components from '../core/Components';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import request from '../core/request';
import gsap from 'gsap';

export default (el, props) => {

    const $el = $(el);
    let xhr = null,
        isLoading = false,
        loadMore = $el.find('[data-page-load-more]'),
        loadMoreContainer = $el.find('[data-page-load-more-wrap]'),
        loader = $el.find('[data-page-load-animation]'),
        container = $el.find('[data-page-container]');


    const init = () => {
        loadMore.on('click', function(e) {
            e.preventDefault();
            fetchItems(loadMore.attr('href'));
        });
    };

    const destroy = () => {
        loadMore.off('click');
    };


    function fetchItems(url) {
        if (isLoading) {
            return;
        }
        
        isLoading = true;

        showLoader();
        
        request
            .get(url)
            .then(({ status, text }) => {
                container.append(text);
                checkPagination();
                Components.parse(container);
                isLoading = false;
                hideLoader();
            })
            .catch(error => {
                console.error(error);
            })
    }

    function showLoader() {
        if (isLoading) {
            gsap.to(loadMore.get(0), { duration: 0.2, opacity: 0 });
            gsap.to(loader.get(0), { duration: 0.2, opacity: 1 });
            pulseLoader();
        }
    }

    function pulseLoader() {
        if (isLoading) {
            gsap.to(loader.get(0), {
                duration: 0.75, scale: 1.1, onComplete: function() {
                    gsap.to(loader.get(0), { duration: 0.75, scale: 1, onComplete: pulseLoader });
                }
            });
        }
    }

    function hideLoader() {
        gsap.killTweensOf(loader.get(0));
        gsap.set(loader.get(0), { clearProps: 'all' });
        gsap.to(loadMore.get(0), { duration: 0.2, opacity: 1 });
    }

    function checkPagination() {
        const lastItem = $el.find('[data-page-next]').last();
        if (lastItem.data('page-next')) {
            loadMore.attr('href', lastItem.data('page-next'));
            lastItem.attr('data-page-next', null);
        } else {
            gsap.to(loadMoreContainer.get(0), {
                duration: 0.25, height: 0, opacity: 0, onComplete: function() {
                    loadMore.off('click');
                    loadMoreContainer.remove();
                }
            });
        }
    }

    return {
        init,
        destroy
    };

};
