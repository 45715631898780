import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const trigger = $el.find('[data-sub-menu-trigger]');
    const subMenu = $el.find('[data-sub-menu]');

    let currentBreakpoint = Viewport.breakpoint.size;
    let isOpen = false;

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);

        if (currentBreakpoint < 980) {
            clickOn();
        } else {
            clickOff();
        }
    };

    const destroy = () => {
        clickOff();
        Viewport.off('breakpoint', onBreakpoint);
    };
    
    function clickOn() {
        trigger.on('click', function(e) {
            e.preventDefault();
            toggle();
        });
    }

    function clickOff() {
        trigger.off('click');
        gsap.set(subMenu.get(0), { height: 0 });
        subMenu.attr('style', null);
        $el.removeClass('-is-open');
        isOpen = false;
    }

    function toggle() {
        if (isOpen) {
            close();
        } else {
            open();
        }
    }

    function open() {
        gsap.set(subMenu.get(0), { height: 'auto' });
        gsap.from(subMenu.get(0), { duration: 0.3, height: 0 });
        isOpen = true;
        $el.addClass('-is-open');
    }

    function close() {
        gsap.to(subMenu.get(0), { duration: 0.3, height: 0 });
        isOpen = false;
        $el.removeClass('-is-open');
    }

    function onBreakpoint(key, data) {
        if (currentBreakpoint < 980 && Viewport.breakpoint.size >= 980) {
            clickOff();
        } else if (currentBreakpoint >= 980 && Viewport.breakpoint.size < 980) {
            clickOn();
        }
        
        currentBreakpoint = Viewport.breakpoint.size;
    }

    return {
        init,
        destroy
    };

};
