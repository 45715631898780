import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

import { MODAL_OPEN, MODAL_CLOSE } from '../lib/events';

export default (el, props) => {

    const $el = $(el);
    const body = $('body');
    const video = $el.find('video');
    let isPlaying = false;
    let viewportHeight = document.documentElement.clientHeight;
    let aspect = 1920 / 817;
    let modalOpen = false;


    const init = () => {
        Dispatch.on(MODAL_OPEN, onShowModal);
        Dispatch.on(MODAL_CLOSE, onCloseModal);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        video.on('loadeddata', onPlay);

        if (video.get(0).canPlayType && video.get(0).canPlayType('video/mp4')) {
            Viewport.on('scroll', onScroll);
            video.attr('src', video.data('src'));
            playVideo();
        }
    };

    const destroy = () => {

    };


    function onPlay() {
        video.off('play', onPlay);
        aspect = video.width() / video.height();

        const oldHeight = $el.height();
        const newHeight = $el.width() / aspect;

        gsap.set(el, { 'padding-bottom': 0, height: oldHeight });
        gsap.to(el, {
            duration: 0.5, height: newHeight, onComplete: function() {
                gsap.set(el, { height: 0, 'padding-bottom': (100 / aspect + '%') });
            }
        });
    }

    function onShowModal() {
        modalOpen = true;
        if (isPlaying) {
            pauseVideo();
        }
    }

    function onCloseModal() {
        modalOpen = false;
        if (!isPlaying && checkVideoShowing()) {
            playVideo();
        }
    }

    function onScroll(key, data) {
        if (!isPlaying && !modalOpen && checkVideoShowing()) {
            playVideo();
        } else if (isPlaying && !modalOpen && !checkVideoShowing()) {
            pauseVideo();
        }
    }

    function checkVideoShowing() {
        if ($el.get(0).getBoundingClientRect().top < (viewportHeight + 10) && $el.get(0).getBoundingClientRect().bottom > 0) {
            return true;
        } else {
            return false;
        }
    }

    function playVideo() {
        isPlaying = true;
        video.get(0).play();
    }

    function pauseVideo() {
        isPlaying = false;
        video.get(0).pause();
    }

    function onBreakpoint(key, data) {

    }

    function onResize(key, data) {
        viewportHeight = document.documentElement.clientHeight;
    }

    return {
        init,
        destroy
    };

};
