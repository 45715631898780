let scrollbarWidth = 0;

export default {
    setScrollbarWidth: function(val) {
        scrollbarWidth = val;
    },
    getScrollbarWidth: function(val) {
        return scrollbarWidth;
    }
};
