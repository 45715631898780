import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import request from '../core/request';
import gsap from 'gsap';

import { MODAL_OPEN, MODAL_CLOSE } from '../lib/events';

export default (el, props) => {

    const $el = $(el),
        body = $('body'),
        hoverVideo = $el.find('[data-list-item-video]');

    let xhr = null,
        isLoading = false,
        modalOpen = false,
        modal = $('.video-modal'),
        loader = $('.video-modal__loader-inner'),
        container,
        closeButton,
        videoUrl,
        loaderCounter = 0,
        hoverVideoElement,
        hoverPlaying = false,
        isHovering = false,
        isPlaying = false;


    const init = () => {
        $el.on('click', function(e) {
            e.preventDefault();
            videoUrl = $el.attr('href');
            openModal();
        });

        if (!modal.length) {
            $('body').append('<div class="video-modal" data-no-tabbing><div class="video-modal__content"></div></div>');
            modal = $('.video-modal');
            modal.append('<a href="#top" class="video-modal__close" aria-label="Close video" data-modal-close><span class="video-modal__close-line"></span><span class="video-modal__close-line"></span></a>');
            modal.append('<div class="video-modal__loader"><div class="video-modal__loader-outer"><div class="video-modal__loader-inner"><svg xmlns="http://www.w3.org/2000/svg" width="314" height="557.7" viewBox="0 0 314 557.7"><path d="M6.7 335.1c-1.8 0-3.5-.7-4.8-2-1.3-1.3-2-3-1.9-4.8.5-47 8.4-71.1 16.7-96.5 8.9-27.2 18.1-55.3 18.1-112.9 0-35 11.6-64.8 33.5-86C90.1 11.7 120.8.3 157 0c36.3.3 66.9 11.7 88.7 32.9 21.9 21.3 33.4 51 33.4 86 0 57.6 9.2 85.7 18.1 112.8 8.3 25.5 16.2 49.5 16.7 96.6 0 1.8-.7 3.5-1.9 4.8-1.3 1.3-3 2-4.8 2H6.7zM45.4 557.7c-2.4 0-4.6-1.3-5.8-3.3-8.4-14.2-36-70.4-39.3-196.2-.1-1.8.6-3.6 1.9-4.9 1.3-1.3 3-2 4.8-2h300c1.8 0 3.5.7 4.8 2 1.3 1.3 1.9 3.1 1.9 4.9-1.4 53.8-7.3 100.8-17.6 139.6-8.2 31.1-17.1 48.8-21.7 56.5-1.2 2-3.4 3.3-5.8 3.3H45.4z"></path></svg></div></div></div>');

            loader = $('.video-modal__loader-inner');
        }


        container = modal.find('.video-modal__content');
        gsap.set(modal.get(0), { opacity: 0, display: 'none', scale: 0.6 });

        if (hoverVideo.length && hoverVideo.get(0).canPlayType && hoverVideo.get(0).canPlayType('video/mp4')) {
            initHoverVideo();
        }
    };

    const destroy = () => {

    };

    function initHoverVideo() {
        gsap.set(hoverVideo.get(0), { display: 'block' });
        hoverVideoElement = hoverVideo.get(0);

        const source = document.createElement('source');

        source.src = hoverVideo.data('src');
        source.type = hoverVideo.data('type');

        hoverVideoElement.appendChild(source);

        $el.on('mouseenter', function() {
            isHovering = true;
            playHoverVideo();
        });

        $el.on('mouseleave', function() {
            isHovering = false;
            stopHoverVideo();
        });

        hoverVideo.on('playing', function() {
            isPlaying = true;
            if (isHovering) {
                showHoverVideo();
            } else {
                stopHoverVideo();
            }
        });

        hoverVideo.on('pause', function() {

        });
    }

    function playHoverVideo() {
        if (!isPlaying) {
            hoverVideoElement.play();
        }
    }

    function stopHoverVideo() {
        if (isPlaying) {
            isPlaying = false;
            hoverVideoElement.pause();

            gsap.to(hoverVideo.get(0), {
                duration: 0.2, opacity: 0, onComplete: function() {
                    hoverVideoElement.currentTime = 0;
                }
            });
        }
    }

    function showHoverVideo() {
        gsap.to(hoverVideo.get(0), { duration: 0.1, opacity: 1 });
    }

    function getFullWidth() {
        let withOutScrollbar = 0;

        body.css({ overflow: 'hidden' });
        withOutScrollbar = modal.width();
        body.css({ overflow: 'visible' });

        return withOutScrollbar;
    }

    function openModal() {
        gsap.set(container.get(0), { opacity: 0 });
        isLoading = true;
        showModal();
    }

    function fetchItem() {
        /*
        if (xhr) {
            xhr.abort();
            xhr = null;
        }
        
        // todo!
        xhr = $.ajax({
                url: videoUrl
            })
            .done(function(html) {
                container.html(html);
                showContent();
                xhr = null;
            });
            
         */

        request
            .get(videoUrl)
            .then(({ status, text }) => {
                container.html(text);
                showContent();
            })
            .catch(error => {
                console.error(error);
            })
    }

    function showModal() {
        Dispatch.emit(MODAL_OPEN);
        onShowModal();
    }

    function closeModal() {
        Dispatch.emit(MODAL_CLOSE);
        onCloseModal();
    }

    function animateLoader() {
        if (loaderCounter < 2) {
            let delay = 0;
            if (loaderCounter > 0) {
                delay = 1;
            }

            loaderCounter++;

            gsap.to(loader.get(0), {
                duration: 0.5, delay: delay, scale: 1.15, ease: 'sine.out', onComplete: function() {
                    gsap.to(loader.get(0), {
                        duration: 0.5, scale: 1, ease: 'sine.out', onComplete: function() {
                            animateLoader();
                        }
                    });
                }
            });
        } else {
            loaderCounter = 0;
        }
    }

    function onShowModal() {
        closeButton = $('[data-modal-close]');

        closeButton.on('click', function(e) {
            e.preventDefault();
            closeModal();
        });

        // todo!
        //Browser.overrideTabbing(modal);
        Viewport.lockTabbing(modal);
        gsap.set(modal.get(0), { display: 'block' });
        gsap.set(modal.get(0), { width: getFullWidth() + 'px', overflow: 'hidden', scale: 0.6 });

        gsap.to(modal.get(0), {
            duration: 0.75, opacity: 1, scale: 1, ease: 'quint.inOut', onComplete: function() {
                animateLoader();
                fetchItem();
                gsap.set(modal.get(0), { overflowY: 'scroll' });
                body.css({ overflow: 'hidden' });
                modal.css({ width: '100%' });
            }
        });

        modalOpen = true;
        $('html').on('keyup', onKeyUp);
    }

    function showContent() {
        gsap.to(container.get(0), { duration: 0.25, opacity: 1 });
    }

    function onCloseModal() {
        closeButton.off('click');

        // todo!
        //Browser.resetTabbing(element);
        Viewport.releaseTabbing($el.get(0));

        body.attr('style', null);
        gsap.set(modal.get(0), { width: getFullWidth() + 'px', overflow: 'hidden' });
        modalOpen = false;
        $(window).off('keyup', onKeyUp);

        gsap.to(modal.get(0), {
            duration: 0.25, opacity: 0, scale: 0.6, onComplete: function() {
                modal.attr('style', null);
                container.html('');
            }
        });
    }

    function onKeyUp(e) {
        console.log(e.keyCode, modalOpen);
        if (e.keyCode === 27 && modalOpen) {
            closeModal();
        }
    }


    return {
        init,
        destroy
    };

};
