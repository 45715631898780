import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);

    let currentBreakpoint = Viewport.breakpoint.size;
    let lastTop = 0;
    let isSmall = false;

    const OFFSET_TOP = 50,
        OFFSET_BUFFER = 10;

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        if (currentBreakpoint >= 980) {
            Viewport.on('scroll', onScroll);
        } else {
            console.log(currentBreakpoint, $el);
            $el.addClass('-small');
            isSmall = true;
        }
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        Viewport.off('scroll', onScroll);
    };
    
    function onScroll(key, data) {
        if (Math.abs(Viewport.scrollTop - lastTop) > OFFSET_BUFFER) {
            toggleSize(Viewport.scrollTop < OFFSET_TOP);
            lastTop = Viewport.scrollTop;
        }
    }

    function toggleSize(small) {
        if (small && isSmall) {
            $el.removeClass('-small');
            isSmall = false;
        } else if (!small && !isSmall) {
            $el.addClass('-small');
            isSmall = true;
        }
    }

    function onBreakpoint(key, data) {
        if (currentBreakpoint < 980 && Viewport.breakpoint.size >= 980) {
            Viewport.on('scroll', onScroll);
            $el.removeClass('-small');
            isSmall = false;
        } else if (currentBreakpoint >= 980 && Viewport.breakpoint.size < 980) {
            Viewport.off('scroll', onScroll);
            $el.addClass('-small');
            isSmall = true;
        }
        
        currentBreakpoint = Viewport.breakpoint.size;
    }

    return {
        init,
        destroy
    };

};
